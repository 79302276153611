*, *::before, *::after {
    box-sizing: content-box;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    width: 200px;
    height: auto
}

.subMenu-item-img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100% !important;
    height: 240px !important;
    overflow: hidden;

    img {
        width: 100% !important;
    }

    img:hover {
        transform: scale(1.1) !important;
    }
}

.font-22 {
    font-size: 22px;
}

.font-14 {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.fontWeight-400 {
    font-weight: 400;
}