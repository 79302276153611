@import '~src/assets/css/variable.less';

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item-group .ant-menu-item-group-title {
    color: #1D1E1D;
}
.ant-menu-submenu-popup {
    background-color: #fff !important;
}
.ant-menu-submenu-popup .ant-menu-sub {
    background-color: #fff !important;
    color: #1D1E1D !important;
    max-width: 100vw !important;
    padding: 32px 0 !important;
    justify-content: center !important;
}
.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item-selected {
    background-color: #fff !important;
    color: #1D1E1D !important;
}


.layout-wrap .header-wrap {
    display: flex;
    justify-content: center;
}

.layout-wrap .layout-header {
    width: 1200px !important;
    padding: 0 !important;
}

.font-16 {
    font-size: 16px;
}
.font-24 {
    font-size: 24px;
}
.subMenu-item-title {
    color: #1D1E1D;
    margin-top: 10px;
}



.subMenu-item-title:hover {
    color: #1890ff;
}


.layout-wrap{
    position: relative;
    // 顶部菜单
    .header-wrap{
        // position: absolute;
        width: 100%;
        background-color: #fff;
        z-index: 9999;
    }
    .layout-header{
        // position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // max-width: 1440px;
        height: 64px;
        padding: 0 120px;
        // margin: 0 auto;
        // background-color: rgba(0, 0, 0, 0.9);
        z-index: 999;

        .left{
            display: flex;
            align-items: center;
            flex: 1;
            width: 100%;
            justify-content: space-between;
            .logo{
                width: 246px;
                height: 44px;
                margin-right: 40px;
                text-indent: -9999px;
                background: url(../assets/images/header-logo.svg) no-repeat 0 0;
                background-size: contain;
            }
            // antd样式修改
            .ant-menu-horizontal{
                // flex: 1;
                line-height: 58px;
                border: none;
                color: #1D1E1D;
                background-color: #fff;
                .anticon-down.ant-menu-item-icon{
                    float: right;
                    margin-left: 5px;
                    margin-top: 23px;
                    font-size: 12px;
                }

            }

        }
        .right{
            position: relative;
            input{
                width: 244px;
                height: 40px;
                padding-right: 50px;
                text-indent: 20px;
                border-radius: 3px;
                color: #999;
                background: #1C202E;
            }
            .anticon-search{
                position: absolute;
                top: 6px;
                right: 8px;
                font-size: 16px;
                color: #fff;
                &::before{
                    display: inline-block;
                    content: '';
                    height: 26px;
                    width: 1px;
                    margin-right: 10px;
                    vertical-align: -4px;
                    background-color: rgba(255, 255, 255, 0.35);
                }
            }
        }
    }
    // 底部信息
    .layout-footer{
        padding: 40px 0 0px;
        color: #C2C2C2;
        background-color: #474747;
        .title{
            font-size: 20px;
            font-weight: 400;
            color: #fff;
            margin-bottom: 12px;
        }
        .item{
            font-size: 14px;
            color: #C2C2C2;
            line-height: 32px;
        }
        
        .product .item:hover{
            color: #077EF5;
            cursor: pointer;
        }
        .info-wrap{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin:0 auto;
            
            .footer-logo{
                flex: 1;
                text-align: center;
                span{
                    display: inline-block;
                    width: 135px;
                    height: 71px;
                    background: url(../assets/images/footer-logo.png);
                }
            }
            .infos{
                flex: 2;
                display: flex;
                justify-content: space-around;
                padding: 0 24px;
                border-right:  1px solid rgba(255,255,255,0.2);
            }
            .follow-us{
                display: flex;
                flex: 0.8;
                text-align: center;
                padding:0 36px;
                justify-content: space-between;

                div{
                    display: flex;
                    flex: 1;
                    align-items: center;
                    flex-direction: column;
                    font-size: 12px;
                }
                span{
                    display: inline-block;
                    width: 72px;
                    height: 72px;
                    margin-bottom: 8px;
                }
                .wechat{
                    background: url(../assets/images/v2Home/wechat.svg) no-repeat center;
                }
                .wechat-video{
                    background: url(../assets/images/v2Home/wechat-video.svg) no-repeat center;
                }
                .wechat-business{
                    background: url(../assets/images/v2Home/wechat-business.svg) no-repeat center;
                }
            }
        }
        .friendly-link{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 54px 0 24px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .title{
                margin-right: 20px;
            }
            .item{
                margin: 12px 0;
                padding: 0 12px;
            }
            .item + .item {
                &::before{
                    display: inline-block;
                    content: '';
                    height: 12px;
                    width: 1px;
                    margin-right: 20px;
                    vertical-align: middle;
                    background-color: #999;
                }
                // border-left: 1px solid #999;
            }
        }
        small{
            display: block;
            text-align: center;
            line-height: 56px;
            font-size: 12px;
            color: rgba(255, 255, 255, 1);
        }
    }
}
