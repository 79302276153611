@import '~src/assets/css/variable.less';

.line-animate-wrap{
    position: relative;
    width: 800px;
    height: 600px;
    background-color: #18202d;
    .line{
        // width: 600px;
        // height: 300px;
        // border-top: 1px solid #fff;
        // border-bottom: none;
        // border-radius: 50%;
    }
    // 定义球的初始值，位置是绝对定位；
    .ball{
        height: 12px;
        width: 12px;
        border-radius: 50%;
        position: absolute;
        top: 40px;
        z-index: 10;
        left: 40px;
        background: #4eddff;
    }

    // 定义动画的流程
    .run-bottom-right {
        display: block;
        animation: run-right-right 4s 0.4s infinite linear, run-right-bottom 4s 0.4s infinite cubic-bezier(.66,.1,1,.41);
        // 动画在动画执行之前和之后不会应用任何样式到目标元素。
        animation-fill-mode: none;

        // animation: run-right-right 3s 0.4s 1 linear, run-right-bottom 3s 0.4s 1 cubic-bezier(.66,.1,1,.41);
        // // 在动画结束后（由 animation-iteration-count 决定），动画将应用该属性值。
        // animation-fill-mode: forwards;
    }

    // 向下走的动画初始及结尾值
    @keyframes run-right-bottom {
        0% {
            top: 40px;
            box-shadow: 0 0 12px @theme-color;
        }

        100% {
            top: 300px;
            background: @theme-color;
            box-shadow: 0 0 100px 40px @theme-color;
        }
    }
    // 向右的动画初始及结尾值
    @keyframes run-right-right {
        0% {
            left: 40px;
            box-shadow: 0 0 12px @theme-color;
            // transform: scale(0.7);
        }

        100% {
            left: 600px;
            background: @theme-color;
            box-shadow: 0 0 100px 40px @theme-color;
            transform: scale(0.45);
        }
    }
}
