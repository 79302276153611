.back-top-wrap{
    font-size: .175rem;
    display: none;
    position: fixed;
    bottom: 3.75rem;
    right: .375rem;
    width: .625rem;
    height: .625rem;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 3px 15px 0px rgba(140, 160, 179, 0.61);
    border-radius: 5px;
    z-index: 999;
    transform: scale(0.8);
    cursor: pointer;
    &.show{
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background: #077EF5;
            .icon{
                display: none;
            }
            .text{
                display: inline-block;
            }
        }
    }
    .icon{
        display: inline-block;
        height: .4rem;
        width: .4rem;
        background: url(../../assets/images/back-top.png);
        background-size: 100% 100%;
    }
    .text{
        display: none;
        color: #FFFFFF;
        line-height: 18px;
        letter-spacing: 3px;
    }
}
