@font-face {
    font-family: 'PingFang SC-Medium';
    src: url('./assets/font/PINGFANGSC-MEDIUM.OTF');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PingFang SC-Regulad';
    src: url('./assets/font/PINGFANGSC-REGULAR.OTF');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PingFang SC-Semibold';
    src: url('./assets/font/PINGFANGSC-SEMIBOLD.OTF');
    font-weight: normal;
    font-style: normal;
}
