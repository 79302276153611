@import './variable.less';

// 重置样式
body,
p,
h1,
h2,
h3,
ul,
li,
input {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

body {
    font-family: PingFangSC-Medium, PingFang SC;
    // min-width: 1200px;
    overflow: auto;
}

h1,
h2,
h3 {
    font-weight: normal;
}

ul {
    list-style: none;
}

input {
    border: 0;
    outline: none;
}

// antd样式修改

// 头部菜单的下拉样式
.ant-menu-submenu-popup {
    // top: 56px !important;
    width: 100%;
    background-color: #111620;

    .ant-menu-sub {
        display: flex;
        justify-content: space-between;
        padding: 16px 120px 30px;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: -10px;
        // color: @white-color;
        color: rgba(255, 255, 255, 0.65);
        background-color: #111620;

        .ant-menu-item-group {
            .ant-menu-item-group-title {
                padding: 16px 0;
                margin-left: 30px;
                border-bottom: 1px solid #666;
                // color: @white-color;
                color: rgba(255, 255, 255, 0.65);
            }

            .ant-menu-item-group-list {
                margin-top: 10px;

                .ant-menu-item {
                    height: 36px;
                }

                .ant-menu-item-selected {
                    background: none;
                }
            }
        }

        .ant-menu-item-selected {
            background-color: #111620;
        }
    }
}

// 公共主体样式
